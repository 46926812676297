import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"
import styles from "../../jss/intro.js"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
  link: {
    color: "#273746",
  },
  sectionDiv: {
    padding: "0px 0px 60px 0px",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" className={classes.sectionDiv}>
        <Grid item xs={11} sm={11} md={8} lg={8}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            At Atlas, we have the necessary tools, experience, and knowledge to
            give you a professional, well designed website. Apart from
            accountant firm web design, we also provide{" "}
            <Link
              className={classes.link}
              to="/seo-company-toronto"
              href="https://atlasagency.ca/seo-company-toronto"
            >
              SEO services
            </Link>{" "}
            and consultation. Our methods include thorough market research,
            keyword analysis, and innovative solutions. Contact us to find out
            more about our accountant firm web design services.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
