import React from "react"
import { navigate } from "gatsby"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
// @material-ui/icons
import addToMailchimp from "gatsby-plugin-mailchimp"
// core components

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { openPopupWidget } from "react-calendly"

import styles from "../../jss/contactStyle.js"

const useStyles = makeStyles(styles)

export class MailChimpForm extends React.Component {
  constructor() {
    super()
    this.state = { email: "", result: null, MESSAGEs: "" }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email, {
      MESSAGE: this.state.MESSAGE,
    })
      .then(data => {
        this.setState({ result: data })
        navigate("/thank-you")
      })
      .catch(() => {})
  }
  handleChange = event => {
    this.setState({ email: event.target.value })
  }

  handleMessage = event => {
    this.setState({ MESSAGE: event.target.value })
  }
  render() {
    const classes = this.props.classes

    const CustomButton = ({ url, prefill, pageSettings, utm }) => {
      const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm })

      return (
        <Button
          style={{
            backgroundColor: "#213f94",
            color: "white",
            marginTop: "10px",
            height: "60px",
            fontSize: "20px"
          }}
          variant="contained"
          onClick={onClick}
        >
          FREE Consultation
        </Button>
      )
    }

    return (
      <div className={classes.section} style={{ marginTop: "-20px" }}>
        <Grid container justify="center">
          <Grid item cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Get Started</h2>
            <p className={classes.description}>
              From accounting services to financial advisors, Atlas Agency has
              helped various types of companies establish and strengthen their
              online presence. We provide valuable market insights that allow
              you to build your web presence.
            </p>
            <p className={classes.description}>
              Our team can help whether you are an independent accountant or the
              owner of a larger accountant firm. We have collaborated with
              various business owners across Canada and over the years we have
              gained valuable insights for creating accounting firm websites.{" "}
            </p>
            <p className={classes.description}>
              Our main goal is to increase your conversion rates and rank you
              higher on search results. That’s why we follow international
              design standards, implement proven strategies, and provide
              responsive websites to help you get the next client.
            </p>
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center" }}
            >
              <p className={classes.description}>
                Book a free 15 minute consultation to learn more about how to
                improve your online presence.
              </p>
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                style={{ textAlign: "center" }}
              >
                <CustomButton url="https://calendly.com/atlasagency/15min" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default () => {
  const classes = useStyles()
  return <MailChimpForm classes={classes} />
}
