import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "accountant-web-design.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)
export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div style={{ padding: "60px 0px 60px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center">
          <Grid
            item
            md={5}
            sm={10}
            lg={5}
            xs={10}
            style={{ paddingBottom: "60px" }}
          >
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="accountant-web-design"
            />
          </Grid>
          <Grid item lg={4} md={10} sm={11} xs={11}>
            <h3 className={classes.title}>Launch with Confidence</h3>
            <h6 className={classes.description}>
              The Importance of Web Design
            </h6>
            <p className={classes.description}>
              Competition can be fierce in the accounting business market,
              making it hard to find clients. However, with the right tools and
              strategy, Atlas Agency can help you find and convert on potential
              clients.
            </p>
            <p className={classes.description}>
              Web design is the key to strengthening your online persona. If
              your website is outdated or difficult to understand, all the
              traffic in the world won’t be enough to convert on clients. Online
              consumers love modern, well designed websites. In fact, they are
              less likely to return to a website if they had an unpleasant
              experience.
            </p>
            <p className={classes.description}>
              With our accountant web design services, we can quickly turn your
              website visitors into paying clients. Investing in a professional
              website can separate you from the crowd, and give your leads the
              push they need to give you a call.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
