import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import styles from "../../jss/intro.js"

import DevicesIcon from "@material-ui/icons/Devices"
import CreateIcon from "@material-ui/icons/Create"
import CloudDoneIcon from "@material-ui/icons/CloudDone"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Grid container justify="center">
        <Grid item xs={10} sm={12} md={8} lg={8} align="center">
          <h2 className={classes.title}>Accountant Website Design</h2>
        </Grid>
        <Grid
          item
          xs={11}
          sm={11}
          md={8}
          lg={11}
          style={{ padding: "20px 0px 0px 0px" }}
        >
          <p className={classes.description}>
            Choosing the first platform or website template that pops up is
            definitely not enough. As a professional accountant web design
            company, we make sure that your investment pays off. Our team spends
            time making sure all the details are covered before we launch your
            website.
          </p>
          <p className={classes.description}>
            We only provide reliable, effective and modern web solutions to all
            of our clients. Here is how we can help your accounting firm get the
            website it deserves:
          </p>
        </Grid>
      </Grid>
      <div style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={5}>
          <Grid item xs={11} sm={10} md={4} lg={4}>
            <InfoArea
              title="Custom Made Websites"
              description="We provide resources that will make your website stand out from competitors. We don't use website templates and create everything from scratch. "
              icon={CreateIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Hosting"
              description="We ensure that your website is available 24/7. At Atlas Agency, we make sure your website is always available for customers to see."
              icon={CloudDoneIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Mobile Optimization"
              description="In today's world, mobile optimization is even more important web optimization. Let us help you optimize your website for mobile, tablet and desktop usage and help rank you higher on Google as a result."
              icon={DevicesIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
